import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.all_loaded
    ? _c(
        VContainer,
        { attrs: { id: "users-table", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: { icon: "mdi-account", inline: "", color: "secondary" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "after-heading",
                    fn: function () {
                      return [
                        _c("h1", { staticClass: "h4" }, [
                          _c("b", [_vm._v(_vm._s(_vm.$tc("user", 2)))]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2031393261
              ),
            },
            [_c("InvestorsTable", { attrs: { Users: _vm.users } })],
            1
          ),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }